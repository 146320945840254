import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import Header from '../Header';
import GoToLimbo from '../Limbo/GoToLimbo';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import ThreadPage from '../Thread';
import CreateThreadPage from '../CreateThread';
import UserListPage from '../UserList';
import ProfilePage from '../Profile';
import SlugsPage from '../Slugs';

const SignInPage = lazy(() => import('../SignIn'));
const PasswordForgetPage = lazy(() => import('../PasswordForget'));
const AccountPage = lazy(() => import('../Account'));
const AdminPage = lazy(() => import('../Admin'));
const LimboPage = lazy(() => import('../Limbo'));
const SignUpPage = lazy(() => import('../SignUp'));

const App = (props) => {
  return (
    <div>
      <Router>
        <Suspense fallback={<div />}>
          <div>
            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser && (
                  <nav className="menu" tabIndex="0">
                    <div className="smartphone-menu-trigger"></div>
                    <Navigation
                      authUser={authUser}
                      firebase={props.firebase}
                    />
                    <GoToLimbo authUser={authUser} />
                  </nav>
                )
              }
            </AuthUserContext.Consumer>

            <main
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Header {...props} />
              <Route
                exact
                path={ROUTES.LANDING}
                component={LandingPage}
              />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route path={ROUTES.THREAD} component={ThreadPage} />
              <Route
                path={ROUTES.CREATE_THREAD}
                component={CreateThreadPage}
              />
              <Route
                path={ROUTES.USER_PROFILE}
                component={ProfilePage}
              />
              <Route path={ROUTES.SLUGS} component={SlugsPage} />
              <Route path={ROUTES.USERS} component={UserListPage} />
              <Route path={ROUTES.IN_LIMBO} component={LimboPage} />
              <Route path={ROUTES.LIMBO} component={LimboPage} />
            </main>
          </div>
        </Suspense>
      </Router>
    </div>
  );
};

export default withAuthentication(App);