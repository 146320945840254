import React, { Component } from 'react';
import { compose } from 'recompose';
import { Switch, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withAuthorization, withEmailVerification } from '../Session';

import SelectedThread from './SelectedThread';
import ThreadList from './ThreadList';

class ThreadPage extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path={ROUTES.THREAD_DETAILS}
            component={SelectedThread}
          />
          <Route exact path={ROUTES.THREAD} component={ThreadList} />
        </Switch>
      </div>
    );
  }
}
const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(ThreadPage);
