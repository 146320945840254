import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from '../Session';
import moment from 'moment';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';

const IconImage = styled.img`
  height: 30;
  width: 30;
  margin-right: 5px;
`;

const IconContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #30302e;
  overflow: hidden;
  margin-bottom: 10px;
  ${ThreadCard}:hover & {
    background: #eb4251;
  }
`;

const IconStrip = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
`;

const ThreadCard = styled.section`
  // margin-bottom: 35px;
`;

const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  // padding: 25px;
  // height: 300px;
  margin-bottom: 5px;
  overflow: hidden;
`;

const MainContainer = styled.div`
  padding-top: 75px;
  width: 60vw;
  @media screen and (max-width: 1024px) {
    padding-top: 75px;
    margin-left: 30;
    margin-right: 30;
    width: calc(100vw - 60px);
  }
`;

const CreateThreadPage = (props) => {
  const [postContent, setPostContent] = useState('');
  const [threadSubject, setThreadSubject] = useState('');

  const isInvalid = postContent === '' || threadSubject === '';
  document.title = 'Facemouth: Create Thread';
  const addToFirestore = (authUser) => {
    props.firebase.db
      .collection('threads')
      .add({
        threadSubject: threadSubject,
        username: authUser.username,
        uid: authUser.uid,
        added: props.firebase.fieldValue.serverTimestamp(),
        updated: props.firebase.fieldValue.serverTimestamp(),
        views: 0,
        replies: 0,
        tagLine:
          authUser.profileInfo && authUser.profileInfo.tagLine
            ? authUser.profileInfo.tagLine
            : null,
      })
      .then((doc) => {
        props.firebase.user(authUser.uid).update({
          posts: props.firebase.fieldValue.increment(1),
          threads: props.firebase.fieldValue.increment(1),
          totalReplies: props.firebase.fieldValue.increment(1),
        });

        props.firebase.db
          .collection('threads')
          .doc(doc.id)
          .collection('posts')
          .add({
            postContent: postContent,
            username: authUser.username,
            uid: authUser.uid,
            tagLine:
              authUser.profileInfo && authUser.profileInfo.tagLine
                ? authUser.profileInfo.tagLine
                : null,
            added: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(props.history.push('/thread/' + doc.id));
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
      });
  };

  return (
    <MainContainer>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && (
            <div>
              <ThreadCard>
                <IconContainer>
                  <h5 style={{ color: '#e6dcd3' }}>Thread title</h5>
                </IconContainer>
                <SubjectStrip
                  style={{
                    backgroundColor: 'white',
                    padding: 20,
                  }}
                >
                  <input
                    style={{ width: '100%' }}
                    placeholder={''}
                    onChange={(e) => setThreadSubject(e.target.value)}
                    value={threadSubject}
                  />
                </SubjectStrip>
              </ThreadCard>
              <div
                style={{
                  backgroundColor: '#eb4251',
                  padding: 10,
                }}
              >
                <h5 style={{ color: '#f2ebe6' }}>
                  Got something to say?
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: '#ef6874',
                  padding: 35,
                }}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={postContent}
                  config={{
                    mediaEmbed: {
                      previewsInData: true,
                      removeProviders: [
                        'instagram',
                        'twitter',
                        'googleMaps',
                        'flickr',
                        'facebook',
                      ],
                    },
                    removePlugins: ['Autoformat' , 'TextTransformation'],
                    placeholder: 'Remember, be nice =]',
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Paragraph',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading1',
                          view: 'h2',
                          title: 'Heading',
                          class: 'ck-heading_heading1',
                        },

                        {
                          model: 'paragraphSpoil',
                          view: {
                            name: 'span',
                            classes: 'ck-heading_spoiler',
                          },
                          title: 'Spoiler',
                          class: 'ck-heading_spoiler',

                          // It needs to be converted before the standard 'heading2'.
                          converterPriority: 'high',
                        },
                      ],
                    },
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'blockQuote',
                      '|',
                      'imageUpload',
                    ],
                    ckfinder: {
                      uploadUrl:
                        'https://img.facemouth.uk/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                    },
                  }}
                  onInit={(editor) => {
                    // console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    setPostContent(data);
                  }}
                  onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                  }}
                />

                <button
                  onClick={() => addToFirestore(authUser)}
                  disabled={isInvalid}
                  style={{
                    backgroundColor: '#30302e',
                    color: '#f2ebe6',
                    paddingLeft: 15,
                    cursor: isInvalid && 'not-allowed',
                  }}
                >
                  Post comment
                  <img
                    style={{
                      height: 32,
                      width: 32,
                    }}
                    src={require('../../assets/fm-icons-Right_arrow.svg')}
                  />
                </button>
              </div>
            </div>
          )
        }
      </AuthUserContext.Consumer>
    </MainContainer>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(CreateThreadPage);
