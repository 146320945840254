import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const inputStyle = {
  padding: 12,
  fontSize: 16,
  display: 'block',
  border: 'none',
  color: '#f2ebe6',
  borderRadius: 0,
  borderBottom: '1px solid #ccc',
  backgroundColor: 'rgba(255,255,255,0)',
};

const SignUpPage = () => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: 30,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#30302e',
        clipPath:
          ' polygon(0 0, 100% 0, 99% 23%, 100% 53%, 99% 76%, 100% 100%, 0 100%)',
      }}
    >
      <img
        style={{ maxWidth: '60vw', width: 350 }}
        src={require('../../assets/fm-icons-Facemouth_logo_large.svg')}
        alt=""
      />
      <h1
        className={'bitSmaller'}
        style={{ color: '#eb4251', textAlign: 'center' }}
      >
        For some reason, you're still here
      </h1>
      <SignUpForm />
      <div style={{ flex: 1 }} />
    </div>
    <div id="cloudbg">
      <div style={{ display: 'flex', flex: 1 }} id="cloud-scroll1">
        <div style={{ display: 'flex', flex: 1 }} id="cloud-scroll2">
          <div
            style={{ display: 'flex', flex: 1 }}
            id="cloud-scroll3"
          >
            <div
              style={{ display: 'flex', flex: 1 }}
              id="cloud-scroll4"
            >
              <div
                style={{ display: 'flex', flex: 1 }}
                id="cloud-scroll5"
              >
                <div
                  style={{ display: 'flex', flex: 1 }}
                  id="cloud-scroll6"
                >
                  <div
                    style={{ display: 'flex', flex: 1 }}
                    id="cloud-scroll70"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  whoAreYa: '',
  isAdmin: false,
  error: null,
  inUse: false,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const letters = /^[A-Za-z0-9_-]+$/;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { username, email, passwordOne, isAdmin, whoAreYa } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            username_lower: username.toLowerCase(),
            email,
            whoAreYa, 
            pendingUser: true,
            roles,
          },
          { merge: true },
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/');
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  handleOnChange = (e) => {
    const { username, email, passwordOne, isAdmin } = this.state;
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    event.target.value &&
      this.props.firebase.db
        .collection('users')
        .where(
          'username_lower',
          '==',
          event.target.value.toLowerCase(),
        )
        .get()
        .then((querySnapshot) => {
          let bumBum = false;
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            bumBum = true;
          });
          this.setState({ inUse: bumBum });
        });
    // console.log(this.state.inUse);
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      whoAreYa,
      isAdmin,
      error,
      inUse,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      inUse === true ||
      !username.match(letters);

    return (
      <form onSubmit={this.onSubmit}>
        <h3 style={{ color: '#e6dcd3' }}>
          Are you sure you want to do this?
        </h3>
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Username"
          style={inputStyle}
        />
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
          style={inputStyle}
        />
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
          style={inputStyle}
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
          style={inputStyle}
        />
          <textarea
          name="whoAreYa"
          value={whoAreYa}
          onChange={this.onChange}
          type="password"
          placeholder="Who the fuck are you? srsly, though."
          style={inputStyle}
        />
        {/* <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />
        </label> */}

        <button
          disabled={isInvalid}
          type="submit"
          style={{
            backgroundColor: '#eb4251',
            color: '#f2ebe6',
            paddingLeft: 15,
            marginBottom: 10,
          }}
        >
          Sign Up
          <img
            style={{ height: 32, width: 32 }}
            src={require('../../assets/fm-icons-Right_arrow.svg')}
          />
        </button>

        {inUse && <p>Sorry that name is in use</p>}
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => <Link to={ROUTES.SIGN_UP}>Sign Up</Link>;

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
