import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import { withAuthorization, withEmailVerification } from '../Session';
import UserList from './UserList';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import GetUserName from '../GetUserName';
import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;
var Element = Scroll.Element;
var scroller = Scroll.scroller;

const MainContainer = styled.div`
  padding-top: 75px;
  width: 60vw;
  height: calc(100vh - 75px);
  display: flex;
  @media screen and (max-width: 1024px) {
    padding-top: 55px;
    height: calc(100vh - 145px);
    margin-left: 30;
    margin-right: 30;
    width: calc(100vw - 60px);
  }
`;

const MessageSpacer = styled.div`
  flex: 1;

  @media screen and (max-width: 1024px) {
    flex: 0;
  }
`;

const RecentSlugs = styled.section`
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin: 0;
    display: none;
    flex: 0;
  }
`;

const SlugMessages = styled.section`
  flex: 3;
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const IconContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #30302e;
  overflow: hidden;
  margin-bottom: 10px;
`;

const IconStrip = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
`;
const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  // padding: 25px;
  height: 100%;
  margin-bottom: 5px;
  overflow: auto;
  @media screen and (max-width: 1024px) {
    height: calc(100% - 75px);
  }
`;

const MessageBox = styled.div`
  margin-bottom: 10px;
  font-size: 0.8em;
  display: flex;
  // min-height: 75px;
`;

const SlugView = (props) => {
  const [messages, setMessages] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  const [messageLimit, setMessageLimit] = useState(10);
  const [messageWithUser, setMessageWithUser] = useState(null);

  const { authUser } = props;

  const idPair = Buffer.from(
    props.match.params.id,
    'base64',
  ).toString('ascii');

  const idPairSplit = idPair.split('_');

  const dmCollection = (uid) => {
    return props.firebase.db
      .collection('dms')
      .doc(idPair)
      .collection('messages');
  };

  const messagesWith = (uid) => {
    return dmCollection(uid).orderBy('sent', 'desc');
  };

  const sendDM = (toUid, messageText) => {
    setInputMessage('');

    return dmCollection(toUid)
      .add({
        fromUid: authUser.uid,
        fromUsername: authUser.username,
        text: messageText,
        sent: props.firebase.fieldValue.serverTimestamp(),
      })
      .then(
        props.firebase.db.collection('dms').doc(idPair).set(
          {
            idPairSplit,
            updated: props.firebase.fieldValue.serverTimestamp(),
          },
          { merge: true },
        ),
      )
      .then(
        scroller.scrollTo('sendBox', {
          smooth: true,
          containerId: 'scrollContainer',
        }),
      );
  };

  useEffect(() => {
    let arr = [...idPairSplit];
    let index = arr.indexOf(authUser.uid);
    index > -1 && arr.splice(index, 1);
    setMessageWithUser(arr[0]);

    messages &&
      messages.length > 0 &&
      scroller.scrollTo('sendBox', {
        smooth: true,
        containerId: 'scrollContainer',
      });
  }, [messages]);

  useEffect(() => {
    const uid = authUser.uid;
    let dataObj = {};
    dataObj[
      'seenBy_' + uid
    ] = props.firebase.fieldValue.serverTimestamp();

    idPair.split('_')[0] === idPair.split('_')[1] &&
      props.history.push('/slugs');

    const unsub = messagesWith()
      .limit(messageLimit)
      .onSnapshot((doc) => {
        const getMessages = [];
        doc.forEach((item, i) => {
          getMessages.unshift(item.data());
        });
        setMessages(getMessages);

        props.firebase.db
          .collection('dms')
          .doc(idPair)
          .set(
            {
              ...dataObj,
            },
            { merge: true },
          );
      });
    return () => {
      unsub();
    };
  }, [idPair, messageLimit]);

  return (
    <MainContainer>
      <RecentSlugs>
        <IconContainer>
          <h5 style={{ color: '#e6dcd3' }}>Recent Slugs</h5>
        </IconContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,
            flex: 1,
          }}
        >
          <UserList authUser={props.authUser} />
        </SubjectStrip>
      </RecentSlugs>
      <SlugMessages>
        <IconContainer>
          <h5 style={{ color: '#e6dcd3' }}>
            {messageWithUser && (
              <GetUserName {...props} uid={messageWithUser} />
            )}
          </h5>
        </IconContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,
            flex: 1,
          }}
          id="scrollContainer"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <button
                  style={{
                    backgroundColor: '#30302e',
                    color: '#f2ebe6',
                    paddingLeft: 15,
                    marginBottom: 10,
                  }}
                  onClick={() => {
                    setMessageLimit(messageLimit + 20);
                  }}
                >
                  Load More
                  <img
                    style={{ height: 32, width: 32 }}
                    src={require('../../assets/fm-icons-Right_arrow.svg')}
                  />
                </button>
              </div>

              {messages ? (
                messages.map((item, i) => {
                  const isMe =
                    item.fromUsername === authUser.username;
                  return (
                    <MessageBox
                      key={i + item.text}
                      style={{
                        justifyContent: isMe
                          ? 'flex-start'
                          : 'flex-end',
                        color: !isMe && '#f2ebe6',
                      }}
                    >
                      {!isMe && <MessageSpacer />}
                      <div
                        style={{
                          flex: 2,
                          backgroundColor: isMe
                            ? '#f2ebe6'
                            : '#30302e',
                          padding: 20,
                          wordBreak: 'break-all',
                        }}
                      >
                        {item.sent &&
                          moment(item.sent.toDate()).fromNow()}
                        <br />
                        {ReactHtmlParser(item.text)}
                      </div>
                      {isMe && <MessageSpacer />}
                    </MessageBox>
                  );
                })
              ) : (
                <div></div>
              )}

              <div style={{ width: '99%' }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={inputMessage}
                  config={{
                    mediaEmbed: {
                      previewsInData: true,
                      removeProviders: [
                        'instagram',
                        'twitter',
                        'googleMaps',
                        'flickr',
                        'facebook',
                      ],
                    },
                    removePlugins: ['Autoformat' , 'TextTransformation'],
                    placeholder: 'Remember, be nice =]',
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Paragraph',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading1',
                          view: 'h2',
                          title: 'Heading',
                          class: 'ck-heading_heading1',
                        },

                        {
                          model: 'paragraphSpoil',
                          view: {
                            name: 'span',
                            classes: 'ck-heading_spoiler',
                          },
                          title: 'Spoiler',
                          class: 'ck-heading_spoiler',

                          // It needs to be converted before the standard 'heading2'.
                          converterPriority: 'high',
                        },
                      ],
                    },
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'blockQuote',
                      '|',
                      'imageUpload',
                    ],
                    ckfinder: {
                      uploadUrl:
                        'https://img.facemouth.uk/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                    },
                  }}
                  onInit={(editor) => {
                    // console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    setInputMessage(data);
                  }}
                  onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                  }}
                />

                <button
                  style={{
                    backgroundColor: '#30302e',
                    color: '#f2ebe6',
                    paddingLeft: 15,
                    marginBottom: 10,
                  }}
                  onClick={() => sendDM(authUser.uid, inputMessage)}
                >
                  Send Message
                  <img
                    style={{ height: 32, width: 32 }}
                    src={require('../../assets/fm-icons-Right_arrow.svg')}
                  />
                </button>
                <Element name="sendBox" />
              </div>
            </div>
          </div>
        </SubjectStrip>
      </SlugMessages>
    </MainContainer>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(SlugView);
