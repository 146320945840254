export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/settings';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const THREAD = '/thread';
export const THREAD_DETAILS = '/thread/:id';
export const CREATE_THREAD = '/create';
export const USER_PROFILE = '/u/:id';
export const SLUGS = '/slugs';
export const SLUGS_CONVO = '/slugs/:id';
export const USERS = '/users';
export const IN_LIMBO = '/inlimbo';
export const LIMBO = '/limbo';
