import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';

const UnreadSlugs = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const { authUser } = props;

  useEffect(() => {
    props.firebase.db
      .collection('dms')
      .where('idPairSplit', 'array-contains', authUser.uid)
      .orderBy('updated', 'desc')

      .onSnapshot((doc) => {
        let uncount = 0;
        doc.forEach((doc) => {
          const item = doc.data();

          let arr = [...item.idPairSplit];
          let index = arr.indexOf(authUser.uid);
          index > -1 && arr.splice(index, 1);
          let isOnBlockList =
            authUser.blockList && authUser.blockList.includes(arr[0]);
          const seenByTF = item['seenBy_' + authUser.uid]
            ? moment(item['seenBy_' + authUser.uid].toDate()) <
              moment(item.updated && item.updated.toDate())
            : true;

          seenByTF && !isOnBlockList && uncount++;
        });
        setUnreadCount(uncount);
      });
  }, []);

  return unreadCount > 0 ? unreadCount : null;
};

const condition = (authUser) => !!authUser;
export default compose(withAuthorization(condition))(UnreadSlugs);
