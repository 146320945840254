import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import moment from 'moment';
import * as ROUTES from '../../constants/routes';

const GoToLimbo = (props) => {
  const { authUser, history, location } = props;
  const [isInLimbo, setIsInLimbo] = useState(false);
  useEffect(() => {
    isInLimbo &&
      location.pathname !== ROUTES.IN_LIMBO &&
      history.push(ROUTES.IN_LIMBO);
    !isInLimbo &&
      location.pathname === ROUTES.IN_LIMBO &&
      history.push(ROUTES.LANDING);
    !authUser.username && props.firebase.auth.signOut();
  });

  useEffect(() => {
    const unsub = props.firebase.db
      .collection('users')
      .doc(authUser.uid)
      .onSnapshot((doc) => {
        doc.exists &&
          setIsInLimbo(
            moment(doc.data().parole && doc.data().parole.toDate()) >
              moment(),
          );
      });

    return () => {
      unsub();
    };
  }, []);

  return null;
};

export default withFirebase(withRouter(GoToLimbo));
