import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: 30,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#30302e',
        clipPath:
          ' polygon(0 0, 100% 0, 99% 23%, 100% 53%, 99% 76%, 100% 100%, 0 100%)',
      }}
    >
      <img
        style={{ maxWidth: '60vw', width: 350 }}
        src={require('../../assets/fm-icons-Facemouth_logo_large.svg')}
        alt=""
      />
      <h1
        className={'bitSmaller'}
        style={{ color: '#eb4251', textAlign: 'center' }}
      >
        For some reason, you're still here
      </h1>

      <PasswordForgetForm />
      <div style={{ flex: 1 }} />
    </div>
    <div id="cloudbg">
      <div style={{ display: 'flex', flex: 1 }} id="cloud-scroll1">
        <div style={{ display: 'flex', flex: 1 }} id="cloud-scroll2">
          <div
            style={{ display: 'flex', flex: 1 }}
            id="cloud-scroll3"
          >
            <div
              style={{ display: 'flex', flex: 1 }}
              id="cloud-scroll4"
            >
              <div
                style={{ display: 'flex', flex: 1 }}
                id="cloud-scroll5"
              >
                <div
                  style={{ display: 'flex', flex: 1 }}
                  id="cloud-scroll6"
                >
                  <div
                    style={{ display: 'flex', flex: 1 }}
                    id="cloud-scroll70"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';
    document.title = 'Facemouth: Forgot Password';
    return (
      <form onSubmit={this.onSubmit}>
        <h3 style={{color: '#e6dcd3'}}>Forgot Password?</h3>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
