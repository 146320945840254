import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import styled from 'styled-components';

const HeaderStrip = styled.section`
width: calc(60vw - 20px);
  background-color: #30302e;
  color: #f2ebe6;
  position: fixed;
  left: calc(20% + 120px);
  padding: 10;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    width: 100vw;
    left: 0;
    padding: 0;
    padding-top: 10;
    padding-bottom: 10;
  }
`;

const HiddenItem = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 5;
    padding-right: 5;
  }
`;

const HeaderThreads = ({
  sortBy,
  setSortBy,
  setIsFetching,
  ThreadsPage,
}) => {
  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser && (
          <HeaderStrip>
            <HiddenItem>
              <div style={{ width: 20 }} />
            </HiddenItem>
            {ThreadsPage ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems:'center',
                }}
              >
                <h5>Sort By</h5>

                <span
                  onClick={() => {
                    setSortBy('newest');
                    setIsFetching(true);
                  }}
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    fontSize: '0.9em',
                    fontWeight: sortBy === 'newest' && '500',
                    borderBottom:
                      sortBy === 'newest' && '2px solid #eb4251',
                  }}
                >
                  Newest
                </span>
                <span
                  onClick={() => {
                    setSortBy('popular');
                    setIsFetching(true);
                  }}
                  style={{
                    fontSize: '0.9em',
                    fontWeight: sortBy !== 'newest' && '500',
                    borderBottom:
                      sortBy !== 'newest' && '2px solid #eb4251',
                  }}
                >
                  Popular
                </span>
              </div>
            ) : (
              <div style={{ height: 24 }} />
            )}

            <HiddenItem>
              <Link to={'/'}>
                <img
                  style={{ height: 20 }}
                  src={require('../../assets/fm-icons-FM_light.svg')}
                />
              </Link>
            </HiddenItem>
          </HeaderStrip>
        )
      }
    </AuthUserContext.Consumer>
  );
};
export default HeaderThreads;
