import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import AvatarImage from '../AvatarImage';
import GetUserName from '../GetUserName';

const UserList = (props) => {
  const [messageList, setMessageList] = useState([]);

  const { authUser } = props;

  useEffect(() => {
    props.firebase.db
      .collection('dms')
      .where('idPairSplit', 'array-contains', authUser.uid)
      .orderBy('updated', 'desc')
      .onSnapshot((doc) => {
        const getAllMessages = [];
        doc.forEach((doc) => {
          getAllMessages.push(doc.data());
        });
        setMessageList(getAllMessages);
      });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {messageList.map((item, i) => {
          let arr = [...item.idPairSplit];
          let index = arr.indexOf(authUser.uid);
          index > -1 && arr.splice(index, 1);
          let isOnBlockList =
            authUser.blockList && authUser.blockList.includes(arr[0]);

          const seenByTF = item['seenBy_' + authUser.uid]
            ? moment(item['seenBy_' + authUser.uid].toDate()) <
              moment(item.updated && item.updated.toDate())
            : true;

          return !isOnBlockList ? (
            <div key={arr[0]}>
              <Link
                to={
                  '/slugs/' +
                  Buffer.from(
                    [...item.idPairSplit].sort().join('_'),
                  ).toString('base64')
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <AvatarImage
                    style={{
                      height: 50,
                      width: 50,
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    uid={arr[0]}
                  />
                  <div
                    style={{
                      paddingLeft: 10,
                      fontSize: '0.8em',
                      fontWeight: 500,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span>
                      {seenByTF && 'Unread - '}{' '}
                      <GetUserName {...props} uid={arr[0]} />
                    </span>
                    <span>
                      {item.updated &&
                        moment(item.updated.toDate()).fromNow()}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 1,
                    marginBottom: 10,
                    height: 1,
                    width: 'calc(100% - 10px)',
                    left: 5,
                    position: 'relative',
                    backgroundColor: '#b3aca9',
                  }}
                />
              </Link>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};
const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(UserList);
