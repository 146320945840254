import React, { useEffect, useState } from 'react';
import { withAuthorization, withEmailVerification } from '../Session';
import { compose } from 'recompose';
import FirestoreImage from '../FirestoreImage';

const UserGallery = (props) => {
  const [userPics, setUserPics] = useState([]);

  useEffect(() => {
    props.firebase
      .user(props.uid)
      .collection('pictures')
      .orderBy('added', 'desc')
      .get()
      .then((querySnapshot) => {
        const loadedPics = [];
        querySnapshot.forEach((doc) => {
          const docId = doc.id;
          let docData = doc.data();
          docData = { ...docData, docId };
          loadedPics.push(docData);
        });
        setUserPics(loadedPics);
      });
  }, [props.firebase, props.uid]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {userPics.map((item, i) => {
        return (
          <div key={item.filename} style={{ padding: 5 }}>
            <FirestoreImage
              filename={item.filename}
              style={{ height: 100, width: 100 }}
            />
          </div>
        );
      })}
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(UserGallery);
