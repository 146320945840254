import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

import styled from 'styled-components';

const BackgroundDiv = styled.div`
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
const AvatarImage = (props) => {
  const { style } = props;

  const [imgBackground, setImgBackground] = useState();
  const [loaded, setLoaded] = useState(false);
  const placeHolder = '/assets/fm-icons-Users.svg';

  const backgroundStyle = {
    backgroundImage: `url(${imgBackground})`,
    backgroundPosition: 'center center',
  };
  const colorBG = { backgroundColor: '#eb4251' };
  const overallStyle = { ...style, ...backgroundStyle };
  const phStyle = { ...style, ...colorBG };

  useEffect(() => {
    props.firebase.storage
      .ref('images')
      .child('' + props.filename)
      .getDownloadURL()
      .then((url) => setImgBackground(url));
  }, [props.filename, props.firebase.storage]);

  return URL ? (
    <a href={imgBackground} rel="noopener noreferrer" target="_blank">
    <div style={style}>
      <BackgroundDiv style={overallStyle}>
        <img
          style={phStyle}
          src={placeHolder}
          alt="placeholder"
          className={loaded ? 'fadeOutImg' : undefined}
        />
        <img
          src={imgBackground}
          alt="user uploaded"
          onLoad={() => setLoaded(true)}
          style={{ height: 0, width: 0 }}
        />
      </BackgroundDiv>
    </div>
    </a>
  ) : null;
};

export default withFirebase(AvatarImage);
