import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from '../Session';
import * as Scroll from 'react-scroll';
import _ from 'lodash';
import SimpleReactLightbox, {
  SRLWrapper,
} from 'simple-react-lightbox';

import { compose } from 'recompose';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import moment from 'moment';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';
import AvatarImage from '../AvatarImage';
import GetUserBirthday from '../GetUserBirthday';
import * as ROLES from '../../constants/roles';

var scroll = Scroll.animateScroll;

const getRandom = () => {
  var min = 0;
  var max = 0.35;
  var random = Math.random() * (max - min) + min;
  return random;
};

const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  padding: 20px;
  margin-bottom: 5px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const IconImage = styled.img`
  height: 30;
  width: 30;
  margin-right: 5px;
`;

const MobHidSpan = styled.span`
  color: white;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const ProfileStrip = styled.section`
  display: flex;
  width: 250px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`;
const MainContainer = styled.div`
  padding-top: 75px;
  width: 60vw;

  @media screen and (max-width: 1024px) {
    margin-left: 30;
    margin-right: 30;
    width: calc(100vw - 60px);
  }
`;

const UserNameSpan = styled.span`
  font-weight: 600;
  font-size: 0.9em;
  display: flex;
  ${ProfileStrip}:hover & {
    color: #eb4251;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #30302e;
  &:hover {
    background-color: #eb4251;
  }
  color: white;
  height: 50px;
  overflow: hidden;
  margin-bottom: 10;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
  // clip-path: polygon(
  //   0 0,
  //   100% 0,
  //   ${100 - getRandom()}% ${100 - getRandom()}%,
  //   ${getRandom()}% ${100 - getRandom()}%
  // );
  @media screen and (max-width: 1024px) {
    height: 44px;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
`;

const ButtonContainer = styled.div`
  flex: 5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8em;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const DownArrow = styled.section`
  background: url('/assets/fm-icons-Down_arrow.svg');
  background-size: 39px 39px;
  height: 39;
  width: 39;
  ${IconContainer}:hover & {
    background: url('/assets/fm-icons-Down_arrow_hover.svg');
    background-size: 39px 39px;
  }
`;

const StyledRule = styled.div`
  margin-top: 1;
  height: 1px;
  width: calc(60vw - 80px);
  left: 40px;
  position: relative;
  background-color: #b3aca9;
  @media screen and (max-width: 1024px) {
    width: calc(90% - 20px);
    left: calc(5% + 10px);
  }
`;

const SelectedThread = (props) => {
  const [threadDetails, setThreadDetails] = useState();
  const [postReplies, setPostReplies] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [limboDuration, setLimboDuration] = useState();
  const [limboReason, setLimboReason] = useState();
  const [isUserTyping, setIsUserTyping] = useState(false);
  const textEditorRef = useRef(null);
  const isInvalid = postContent === '';

  useEffect(() => {
    props.firebase.db
      .collection('threads')
      .doc(props.match.params.id)
      .onSnapshot((querySnapshot) => {
        querySnapshot.exists
          ? setThreadDetails(querySnapshot.data())
          : props.history.push('/');
        querySnapshot.exists &&
          (document.title =
            'Facemouth: ' + querySnapshot.data().threadSubject);
      });

    props.firebase.db
      .collection('threads')
      .doc(props.match.params.id)
      .update({ views: props.firebase.fieldValue.increment(1) });
  }, []);

  useEffect(() => {
    const unsub = props.firebase.db
      .collection('threads')
      .doc(props.match.params.id)
      .collection('posts')
      .orderBy('added', 'asc')
      .onSnapshot((querySnapshot) => {
        var posts = [];
        querySnapshot.forEach((doc) => {
          posts.push({ ...doc.data(), docId: doc.id });
        });
        setPostReplies(posts);
      });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    const isTypingCheck = setInterval(() => {
      threadDetails &&
      threadDetails.isUserTyping &&
      moment(threadDetails.isUserTyping.toDate()) >
        moment().add(-10, 'seconds')
        ? setIsUserTyping(true)
        : setIsUserTyping(false);
    }, 1000);
    return () => {
      clearInterval(isTypingCheck);
    };
  }, [threadDetails]);

  const addToFirestore = (authUser) => {
    props.firebase.db
      .collection('threads')
      .doc(props.match.params.id)
      .collection('posts')
      .add({
        postContent: postContent,
        username: authUser.username,
        uid: authUser.uid,
        added: props.firebase.fieldValue.serverTimestamp(),
        tagLine:
          authUser.profileInfo && authUser.profileInfo.tagLine
            ? authUser.profileInfo.tagLine
            : null,
      })
      .then((doc) => {
        setPostContent('');
        scroll.scrollToBottom();

        props.firebase.db
          .collection('threads')
          .doc(props.match.params.id)
          .update({
            lastUserPost: authUser.username,
            updated: props.firebase.fieldValue.serverTimestamp(),
            replies: props.firebase.fieldValue.increment(1),
          });

        props.firebase.user(threadDetails.uid).update({
          totalReplies: props.firebase.fieldValue.increment(1),
        });

        props.firebase.user(authUser.uid).update({
          posts: props.firebase.fieldValue.increment(1),
        });
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
      });
  };

  const handleDeletePost = (postId) => {
    props.firebase.db
      .collection('threads')
      .doc(props.match.params.id)
      .collection('posts')
      .doc(postId)
      .delete()
      .then(
        props.firebase.db
          .collection('threads')
          .doc(props.match.params.id)
          .update({
            replies: props.firebase.fieldValue.increment(-1),
          }),
      );
  };

  const handleDeleteThread = () => {
    props.firebase.db
      .collection('threads')
      .doc(props.match.params.id)
      .delete();
  };

  const addToLimbo = (uid, parole, username) => {
    props.firebase.db.collection('limbo').add({
      uid: uid,
      reason: limboReason,
      parole: parole,
      username: username,
    });

    props.firebase.db.collection('users').doc(uid).update({
      parole: parole,
    });
  };

  const debounceLoadData = useCallback(
    _.debounce((postContent) => {
      handleIsTyping(postContent);
    }, 1000),
    [],
  );

  useEffect(() => {
    debounceLoadData(postContent);
  }, [postContent]);

  const handleIsTyping = (getPostContent) => {
    getPostContent != '' &&
      props.firebase.db
        .collection('threads')
        .doc(props.match.params.id)
        .update({
          isUserTyping: props.firebase.fieldValue.serverTimestamp(),
        });
  };

  return (
    <MainContainer>
      {threadDetails && (
        <SubjectStrip
          style={{
            clipPath: `polygon(${getRandom()}% ${getRandom()}%, ${
              100 - getRandom()
            }% ${getRandom()}%,100% 100%,0 100%)`,
          }}
        >
          <Link to={'/u/' + threadDetails.username}>
            <ProfileStrip>
              <div style={{ marginRight: 10 }}>
                <AvatarImage
                  style={{
                    height: 60,
                    width: 60,
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                  uid={threadDetails.uid}
                />
              </div>
              <div
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <UserNameSpan>
                  {threadDetails.username}
                  <GetUserBirthday
                    uid={threadDetails.uid}
                    {...props}
                  />
                </UserNameSpan>
                <span style={{ fontWeight: 500, fontSize: '0.8em' }}>
                  {threadDetails.tagLine && threadDetails.tagLine}
                </span>
                <span
                  style={{
                    fontSize: '0.8em',
                    color: '#b3aca9',
                  }}
                >
                  {moment(threadDetails.added.toDate()).fromNow()}
                </span>
              </div>
            </ProfileStrip>
          </Link>
          <div style={{ paddingTop: 20 }}>
            <span
              style={{
                flex: 3,
                fontSize: '1.1  em',
                fontWeight: 500,
                paddingRight: 20,
                // display: '-webkit-box',
                // WebkitLineClamp: '3',
                // WebkitBoxOrient: 'vertical',
                // overflow: 'hidden',
              }}
            >
              {threadDetails.threadSubject}
            </span>
            <div style={{ height: 25, flex: 1 }} />
            <span style={{ fontSize: '0.8em', fontWeight: 400 }}>
              {ReactHtmlParser(
                postReplies.length > 0 && postReplies[0].postContent,
              )}
            </span>
            <div style={{ height: 20 }} />
          </div>
          <div
            style={{
              // width: '100%',
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser &&
                !!authUser.roles[ROLES.ADMIN] && (
                  <div>
                    <Popup
                      trigger={
                        <img
                          style={{
                            alignSelf: 'flex-end',
                            height: 39,
                            width: 39,

                            filter:
                              'invert(100%) sepia(0%) saturate(0%) hue-rotate(255deg) brightness(0%) contrast(0%)',
                          }}
                          src={require('../../assets/fm-icons-Menu_close.svg')}
                        />
                      }
                      position="left center"
                    >
                      <div
                        onClick={() => {
                          handleDeleteThread();
                        }}
                      >
                        <h1>Yes I'm Sure</h1>
                      </div>
                    </Popup>
                  </div>
                )
              }
            </AuthUserContext.Consumer>
            <div>
              <img
                onClick={() => {
                  scroll.scrollToBottom();

                  textEditorRef.current.editor.setData(
                    postContent +
                      '<blockquote><strong>' +
                      threadDetails.username +
                      '</strong>' +
                      postReplies[0].postContent,
                    +'</blockquote><p></p>',
                  );
                }}
                style={{
                  alignSelf: 'flex-end',
                  height: 39,
                  width: 39,

                  filter:
                    'invert(100%) sepia(0%) saturate(0%) hue-rotate(255deg) brightness(0%) contrast(0%)',
                }}
                src={require('../../assets/fm-icons-Quote.svg')}
              />
            </div>
          </div>
        </SubjectStrip>
      )}
      <IconContainer onClick={() => scroll.scrollToBottom()}>
        <ButtonContainer>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconImage
                src={require('../../assets/fm-icons-Comments.svg')}
              />
              <span>
                {threadDetails && threadDetails.replies}
                <MobHidSpan> posts</MobHidSpan>
              </span>
            </div>
            <div style={{ width: 10 }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconImage
                src={require('../../assets/fm-icons-Views.svg')}
              />
              <span>
                {threadDetails && threadDetails.views}{' '}
                <MobHidSpan>views</MobHidSpan>
              </span>
            </div>
            <div style={{ width: 10 }} />
            {threadDetails && threadDetails.lastUserPost && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 200,
                  }}
                >
                  <IconImage
                    src={require('../../assets/fm-icons-Users.svg')}
                  />
                  <MobHidSpan>Last post by </MobHidSpan>
                  <div style={{ width: 5 }} />
                  <strong
                    style={{
                      whiteSpace: 'nowrap',
                      width: 'auto',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {threadDetails.lastUserPost}
                  </strong>
                </div>
                <div style={{ width: 5 }} />
              </>
            )}
            <div>
              {isUserTyping && (
                <div
                  style={{
                    display: 'flex',
                    flexDiection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <IconImage
                    src={require('../../assets/fm-icons-Quote.svg')}
                    style={{ marginRight: -3 }}
                  />
                  <h2>...</h2>
                </div>
              )}
            </div>
          </div>
        </ButtonContainer>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <DownArrow onClick={() => scroll.scrollToBottom()} />
        </div>
      </IconContainer>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && (
            <div
              style={{
                backgroundColor: 'white',
              }}
            >
              {postReplies
                .filter((item) => {
                  return authUser.blockList
                    ? !authUser.blockList.includes(item.uid)
                    : item;
                })
                .map((item, i) => {
                  return (
                    i !== 0 && (
                      <div key={item.docId}>
                        <SubjectStrip>
                          <Link to={'/u/' + item.username}>
                            <ProfileStrip>
                              <div style={{ marginRight: 10 }}>
                                <AvatarImage
                                  style={{
                                    height: 60,
                                    width: 60,
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                  }}
                                  uid={item.uid}
                                />
                              </div>
                              <div
                                style={{
                                  textAlign: 'left',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <UserNameSpan>
                                  {item.username}
                                  <GetUserBirthday
                                    uid={item.uid}
                                    {...props}
                                  />
                                </UserNameSpan>
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: '0.8em',
                                  }}
                                >
                                  {item.tagLine && item.tagLine}
                                </span>
                                <span
                                  style={{
                                    fontSize: '0.8em',
                                    color: '#b3aca9',
                                  }}
                                >
                                  {moment(
                                    item.added && item.added.toDate(),
                                  ).fromNow()}
                                </span>
                              </div>
                            </ProfileStrip>
                          </Link>

                          <div style={{ paddingTop: 20, flex: 1 }}>
                            <span
                              style={{
                                fontSize: '0.8em',
                                fontWeight: 400,
                              }}
                            >
                              <SimpleReactLightbox>
                                <SRLWrapper
                                  options={{
                                    thumbnails: {
                                      showThumbnails: false,
                                    },
                                  }}
                                >
                                  {ReactHtmlParser(item.postContent)}
                                </SRLWrapper>
                              </SimpleReactLightbox>
                            </span>
                            <div style={{ height: 20 }} />
                          </div>
                          <div
                            style={{
                              // width: '100%',
                              flex: 0.2,
                              // display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                              // // flexDirection: 'column',
                            }}
                          >
                            <AuthUserContext.Consumer>
                              {(authUser) =>
                                authUser &&
                                !!authUser.roles[ROLES.ADMIN] && (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <Popup
                                      trigger={
                                        <img
                                          style={{
                                            alignSelf: 'flex-end',
                                            height: 39,
                                            width: 39,

                                            filter:
                                              'invert(100%) sepia(0%) saturate(0%) hue-rotate(255deg) brightness(0%) contrast(0%)',
                                          }}
                                          src={require('../../assets/fm-icons-Admin_area.svg')}
                                        />
                                      }
                                      modal
                                      closeOnDocumentClick
                                    >
                                      <div
                                        style={{
                                          margin: 'auto',
                                        }}
                                      >
                                        Duration:
                                        <input
                                          value={limboDuration}
                                          onChange={(e) =>
                                            setLimboDuration(
                                              e.target.value,
                                            )
                                          }
                                        />
                                        <br /> Reason:
                                        <input
                                          value={limboReason}
                                          onChange={(e) =>
                                            setLimboReason(
                                              e.target.value,
                                            )
                                          }
                                        />
                                        <br />
                                        Release Time:{' '}
                                        {moment()
                                          .add(
                                            limboDuration,
                                            'minute',
                                          )
                                          .format('DD/MM/YY HH:mm')
                                          .toString()}
                                        <br />
                                        <button
                                          onClick={() => {
                                            addToLimbo(
                                              item.uid,
                                              moment()
                                                .add(
                                                  limboDuration,
                                                  'minute',
                                                )
                                                .toDate(),
                                              item.username,
                                            );
                                          }}
                                        >
                                          Do it.
                                        </button>
                                      </div>
                                    </Popup>
                                  </div>
                                )
                              }
                            </AuthUserContext.Consumer>
                            <AuthUserContext.Consumer>
                              {(authUser) =>
                                authUser &&
                                !!authUser.roles[ROLES.ADMIN] && (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <Popup
                                      trigger={
                                        <img
                                          style={{
                                            alignSelf: 'flex-end',
                                            height: 39,
                                            width: 39,

                                            filter:
                                              'invert(100%) sepia(0%) saturate(0%) hue-rotate(255deg) brightness(0%) contrast(0%)',
                                          }}
                                          src={require('../../assets/fm-icons-Menu_close.svg')}
                                        />
                                      }
                                      position="left center"
                                    >
                                      <div
                                        onClick={() => {
                                          handleDeletePost(
                                            item.docId,
                                          );
                                        }}
                                      >
                                        <h1>Yes i'm sure!</h1>
                                      </div>
                                    </Popup>
                                  </div>
                                )
                              }
                            </AuthUserContext.Consumer>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <img
                                onClick={() => {
                                  scroll.scrollToBottom();

                                  textEditorRef.current.editor.setData(
                                    postContent +
                                      '<blockquote><strong>' +
                                      item.username +
                                      '</strong>' +
                                      item.postContent +
                                      '</blockquote><p></p>',
                                  );
                                }}
                                style={{
                                  alignSelf: 'flex-end',
                                  height: 39,
                                  width: 39,

                                  filter:
                                    'invert(100%) sepia(0%) saturate(0%) hue-rotate(255deg) brightness(0%) contrast(0%)',
                                }}
                                src={require('../../assets/fm-icons-Quote.svg')}
                              />
                            </div>
                          </div>
                        </SubjectStrip>

                        {postReplies.length !== i + 1 && (
                          <StyledRule />
                        )}
                      </div>
                    )
                  );
                })}
            </div>
          )
        }
      </AuthUserContext.Consumer>

      <div
        style={{
          backgroundColor: '#eb4251',
          padding: 10,
        }}
      >
        <h5 style={{ color: '#f2ebe6' }}>Got something to say?</h5>
      </div>
      <div
        style={{
          backgroundColor: '#ef6874',
          padding: 35,
        }}
      >
        {threadDetails &&
          (threadDetails.added.toDate() >
          moment().add(-3, 'days').toDate() ? (
            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser && (
                  <div>
                    <CKEditor
                      ref={textEditorRef}
                      editor={ClassicEditor}
                      data={postContent}
                      config={{
                        mediaEmbed: {
                          previewsInData: true,
                          removeProviders: [
                            'instagram',
                            'twitter',
                            'googleMaps',
                            'flickr',
                            'facebook',
                          ],
                        },
                        removePlugins: [
                          'Autoformat',
                          'TextTransformation',
                        ],
                        placeholder: 'Remember, be nice =]',
                        heading: {
                          options: [
                            {
                              model: 'paragraph',
                              title: 'Paragraph',
                              class: 'ck-heading_paragraph',
                            },
                            {
                              model: 'heading1',
                              view: 'h2',
                              title: 'Heading',
                              class: 'ck-heading_heading1',
                            },

                            {
                              model: 'paragraphSpoil',
                              view: {
                                name: 'span',
                                classes: 'ck-heading_spoiler',
                              },
                              title: 'Spoiler',
                              class: 'ck-heading_spoiler',

                              // It needs to be converted before the standard 'heading2'.
                              converterPriority: 'high',
                            },
                          ],
                        },
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          'blockQuote',
                          '|',
                          'imageUpload',
                        ],
                        ckfinder: {
                          uploadUrl:
                            'https://img.facemouth.uk/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                        },
                      }}
                      onInit={(editor) => {
                        // console.log(
                        //   'Editor is ready to use!',
                        //   editor,
                        // );
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        setPostContent(data);
                      }}
                      onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                      }}
                    />
                    <button
                      disabled={isInvalid}
                      onClick={() => addToFirestore(authUser)}
                      style={{
                        backgroundColor: '#30302e',
                        color: '#f2ebe6',
                        paddingLeft: 15,
                        cursor: isInvalid && 'not-allowed',
                      }}
                    >
                      Post comment
                      <img
                        style={{
                          height: 32,
                          width: 32,
                        }}
                        src={require('../../assets/fm-icons-Right_arrow.svg')}
                      />
                    </button>
                  </div>
                )
              }
            </AuthUserContext.Consumer>
          ) : (
            <h1>Sorry mates, threads closed.</h1>
          ))}
      </div>
    </MainContainer>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(SelectedThread);
