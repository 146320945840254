import React, { useEffect , useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import UnreadSlugs from '../Slugs/UnreadSlugs';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const Navigation = (props) => {
  const [pendUser, setPendUser] = useState(0)
  useEffect(() => {
    let setOnline = () => {
      return props.firebase
        .user(props.authUser.uid)
        .set(
          { lastOnline: props.firebase.fieldValue.serverTimestamp() },
          { merge: true },
        );
    };

    const fiveMinutes = 1000 * 60 * 5; // five minutes, or whatever makes sense for your app
    setOnline();
    const unsub = setInterval(() => {
      setOnline();
    }, fiveMinutes);

    return () => {
      clearInterval(unsub);
    };
  }, [props.firebase, props.authUser.uid]);

  useEffect(() => {
    const unsub = props.firebase.db
    .collection('users')  
      .where('pendingUser', '==', true)
      .get()
      .then(
        doc =>  doc.forEach((i) => setPendUser(pendUser + 1))
      )

    return () => {
      unsub();
    };
  }, []);

  return (
    <div tabIndex="0" style={{}}>
      <Link to={ROUTES.LANDING}>
        <header className="logo">
          <img
            alt="Facemouth Logo"
            src={require('../../assets/fm-icons-Facemouth_logo_small.svg')}
          />
        </header>
      </Link>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && <NavigationAuth authUser={authUser} pendUser={pendUser} />
        }
      </AuthUserContext.Consumer>
    </div>
  );
};

const NavigationAuth = ({ authUser, pendUser }) => {
  return (
    <ul>
      <Link to={ROUTES.CREATE_THREAD}>
        <li tabIndex="0" className="icon-create-thread">
          <span>Create thread</span>
        </li>
      </Link>

      <Link to={ROUTES.SLUGS}>
        <li tabIndex="0" className="icon-slugs">
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser && <UnreadSlugs authUser={authUser} />
            }
          </AuthUserContext.Consumer>{' '}
          <span>Slugs</span>
        </li>
      </Link>
      <Link to={ROUTES.ACCOUNT}>
        <li tabIndex="0" className="icon-settings">
          <span>Settings</span>
        </li>
      </Link>
      <Link to={ROUTES.USERS}>
        <li tabIndex="0" className="icon-users">
          <span>Users</span>
        </li>
      </Link>
      <Link to={ROUTES.LIMBO}>
        <li tabIndex="0" className="icon-limbo">
          <span>Limbo</span>
        </li>
      </Link>
      {!!authUser.roles[ROLES.ADMIN] && (
        <Link to={ROUTES.ADMIN}>
          <li tabIndex="0" className="icon-admin">
            <span>Admin</span> {pendUser !== 0 && pendUser }
          </li>
        </Link>
      )}
      <li tabIndex="0" className="icon-sign-out">
        <span>
          <SignOutButton />
        </span>
      </li>
    </ul>
  );
};

export default Navigation;
