import React, { Component  } from 'react';
import { compose } from 'recompose';
import { Switch, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from '../Session';
import SlugList from './SlugList';
import SlugView from './SlugView';

class SlugsPage extends Component {
  render() {
    document.title = 'Facemouth: Slugs';
    return (
      <div>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser && (
              <Switch>
                  <Route
                    exact
                    path={ROUTES.SLUGS}
                    render={(props) => (
                      <SlugList {...props} authUser={authUser} />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.SLUGS_CONVO}
                    render={(props) => (
                      <SlugView {...props} authUser={authUser} />
                    )}
                  />
              </Switch>
            )
          }
        </AuthUserContext.Consumer>
      </div>
    );
  }
}
const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(SlugsPage);
