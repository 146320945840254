import React, { useState, useEffect } from 'react';
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from '../Session';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'recompose';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';
import HeaderThreads from '../Header/HeaderThreads';
import GetUserBirthday from '../GetUserBirthday';
import AvatarImage from '../AvatarImage';
const getRandom = () => {
  var min = 0;
  var max = 0.5;
  var random = Math.random() * (max - min) + min;
  return random;
};

const ThreadCard = styled.section`
  margin-bottom: 35px;
`;

const MobHidSpan = styled.span`
  color: white;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const ProfileStrip = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20;
`;

const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  padding: 25px;
  height: 150px;
  margin-bottom: 5px;
  overflow: hidden;
  background-color: white;
`;

const RightArrow = styled.section`
  background: url('/assets/fm-icons-Right_arrow.svg');
  height: 39;
  width: 39;
  ${ThreadCard}:hover & {
    background: url('/assets/fm-icons-Right_arrow_hover.svg');
  }
`;

const IconImage = styled.img`
  height: 30;
  width: 30;
  margin-right: 5px;
`;

const IconContainer = styled.section`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background: #30302e;
  height: 50px;
  overflow: hidden;
  marginbottom: 10;
  color: #f2ebe6;
  ${ThreadCard}:hover & {
    background: #eb4251;
  }
`;

const IconStrip = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
`;

const breakpointColumnsObj = {
  default: 3,
  1300: 2,
  500: 1,
};

function compare(a, b) {
  const bandA = a.replies;
  const bandB = b.replies;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison * -1;
}

const ThreadList = (props) => {
  const [threads, setThreads] = useState([]);
  const [threadLimit, setThreadLimit] = useState(20);
  const [isFetching, setIsFetching] = useState(true);
  const [sortBy, setSortBy] = useState('newest');

  document.title = 'Facemouth';

  const mostPop = props.firebase.db
    .collection('threads')
    .where('added', '>', moment().add(-1, 'day').toDate())
    .limit(threadLimit);

  const mostRecent = props.firebase.db
    .collection('threads')
    .orderBy('added', 'desc')
    .limit(threadLimit);

  useEffect(() => {
    const unsubNew = mostRecent.onSnapshot((querySnapshot) => {
      var threads = [];
      querySnapshot.forEach((doc) => {
        const docId = doc.id;
        let docData = doc.data();
        docData = { ...docData, docId };
        threads.push(docData);
      });
      sortBy === 'newest' && setThreads(threads);
      setIsFetching(false);
    });

    mostPop.get().then((querySnapshot) => {
      var threads = [];
      querySnapshot.forEach((doc) => {
        const docId = doc.id;
        let docData = doc.data();
        docData = { ...docData, docId };
        threads.push(docData);
      });

      sortBy !== 'newest' && setThreads(threads.sort(compare));
      setIsFetching(false);
    });

    return () => {
      unsubNew();
    };
  }, [isFetching]);

  return (
    <div>
      <HeaderThreads
        ThreadsPage
        history={props.history}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setIsFetching={setIsFetching}
      />
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && (
            <Masonry
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
              breakpointCols={breakpointColumnsObj}
            >
              {(sortBy == 'newest'
                ? [
                    ...threads
                      .filter(
                        ({ added }) =>
                          moment(added.toDate()).toDate() >
                          moment().add(-3, 'hour').toDate(),
                      )
                      .sort((a, b) => b.updated - a.updated),

                    ...threads
                      .filter(
                        ({ added }) =>
                          moment(added.toDate()).toDate() <
                          moment().add(-3, 'hour').toDate(),
                      )
                      .sort((a, b) => b.added - a.added),
                  ]
                : threads
              )
                .filter((item) => {
                  return authUser.blockList
                    ? !authUser.blockList.includes(item.uid)
                    : item;
                })
                .map((item, i) => {
                  return (
                    <Link
                      key={item.docId}
                      to={'thread/' + item.docId}
                    >
                      <ThreadCard>
                        <SubjectStrip
                          style={{
                            clipPath: `polygon(${getRandom()}% ${getRandom()}%,${
                              100 - getRandom()
                            }% ${getRandom()}%,100% 100%,0 100%  )`,
                          }}
                        >
                          <ProfileStrip>
                            <AvatarImage
                              style={{
                                height: 60,
                                width: 60,
                                objectFit: 'cover',
                                borderRadius: '50%',
                              }}
                              uid={item.uid}
                            />
                            <div
                              style={{
                                textAlign: 'right',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: '0.9em',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {item.uid && (
                                  <GetUserBirthday
                                    {...props}
                                    uid={item.uid}
                                  />
                                )}
                                {item.username}{' '}
                              </span>
                              <span
                                style={{
                                  fontSize: '0.8em',
                                  color: '#b3aca9',
                                }}
                              >
                                {moment(
                                  item.added.toDate(),
                                ).fromNow()}
                              </span>
                            </div>
                          </ProfileStrip>
                          <span
                            style={{
                              fontWeight: 500,
                              fontSize: '1.1em',
                              display: '-webkit-box',
                              WebkitLineClamp: '3',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              marginBottom: 20,
                            }}
                          >
                            {item.threadSubject}
                          </span>
                        </SubjectStrip>
                        <IconContainer
                          style={{
                            clipPath: `polygon(0 0, 100% 0, ${
                              100 - getRandom()
                            }% ${
                              100 - getRandom()
                            }%, ${getRandom()}% ${
                              100 - getRandom()
                            }%)`,
                          }}
                        >
                          <IconStrip>
                            <div
                              style={{
                                flex: 5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                fontSize: '0.8em',
                              }}
                            >
                              <IconImage
                                src={require('../../assets/fm-icons-Comments.svg')}
                              />
                              <span>
                                {item.replies}{' '}
                                <MobHidSpan>posts</MobHidSpan>
                              </span>
                              <div style={{ width: 10 }} />
                              <IconImage
                                src={require('../../assets/fm-icons-Views.svg')}
                              />
                              <span>
                                {item.views}{' '}
                                <MobHidSpan> views</MobHidSpan>
                              </span>
                              <div style={{ width: 10 }} />
                              {item && item.lastUserPost && (
                                <>
                                  <IconImage
                                    src={require('../../assets/fm-icons-Users.svg')}
                                  />
                                  <strong
                                    style={{
                                      maxWidth: 115,
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {item && item.lastUserPost}
                                  </strong>
                                </>
                              )}
                            </div>
                          </IconStrip>
                          <div>
                            <RightArrow />
                          </div>
                        </IconContainer>
                      </ThreadCard>
                    </Link>
                  );
                })}
            </Masonry>
          )
        }
      </AuthUserContext.Consumer>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          style={{
            backgroundColor: '#30302e',
            color: '#f2ebe6',
            paddingLeft: 15,
          }}
          onClick={() => {
            setThreadLimit(threadLimit + 20);
            setIsFetching(true);
          }}
        >
          Load More
          <img
            style={{ height: 32, width: 32 }}
            src={require('../../assets/fm-icons-Down_arrow.svg')}
          />
        </button>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(ThreadList);
