import React, { useEffect, useState } from 'react';
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from '../Session';
import AvatarImage from '../AvatarImage';

import { compose } from 'recompose';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import moment from 'moment';
import UserGallery from './UserGallery';
import styled from 'styled-components';
import * as ROLES from '../../constants/roles';

// const IconImage = styled.img`
//   height: 30;
//   width: 30;
//   margin-right: 5px;
// `;

const MainContainer = styled.div`
  padding-top: 75px;
  width: 60vw;
  display: flex;
  @media screen and (max-width: 1024px) {
    padding-top: 75px;
    margin-left: 30;
    margin-right: 30;
    flex-direction: column;
    width: 95vw;
  }
`;

const RecentSlugs = styled.section`
  flex: 1;
  margin-right: 20px;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`;

const SlugMessages = styled.section`
  flex: 3;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`;

const TitleContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #30302e;
  overflow: hidden;
  margin-bottom: 10px;
`;

const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  // padding: 25px;

  margin-bottom: 5px;
  @media screen and (max-width: 1024px) {
  }
`;

const ProfilePage = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [lastTenThreads, setLastTenThreads] = useState([]);
  // const [lastTenPosts, setLastTenPosts] = useState([]);

  useEffect(() => {
    props.firebase.db
      .collection('users')
      .where('username', '==', props.match.params.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let docData = {
            username: doc.data().username,
            profileInfo: doc.data().profileInfo,
            lastOnline: doc.data().lastOnline,
            posts: doc.data().posts,
            threads: doc.data().threads,
            totalReplies: doc.data().totalReplies,
            docId: doc.id,
          };

          setUserInfo(docData);
        });
      });
  }, [props.match.params.id, props.firebase.db]);

  useEffect(() => {
    document.title = 'Facemouth: ' + props.match.params.id;
    props.firebase.db
      .collection('threads')
      .where('username', '==', props.match.params.id)
      .orderBy('added', 'desc')
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const getLastThreads = [];
        querySnapshot.forEach((doc) => {
          getLastThreads.push({ ...doc.data(), docId: doc.id });
        });
        setLastTenThreads(getLastThreads);
      });
  }, [props.match.params.id, props.firebase.db]);

  return userInfo ? (
    <MainContainer>
      <RecentSlugs>
        <TitleContainer>
          <h5 style={{ color: '#e6dcd3' }}>Bits</h5>
        </TitleContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,

            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <AvatarImage
              style={{
                height: 100,
                width: 100,
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              uid={userInfo.docId}
            />
            <h1>{userInfo.username}</h1>
            <div>Threads: {userInfo.threads && userInfo.threads}</div>
            <div>Posts: {userInfo.posts && userInfo.posts}</div>
            <div>
              Average Replies:{' '}
              {userInfo.threads &&
                userInfo.totalReplies &&
                Math.floor(
                  userInfo.totalReplies / userInfo.threads,
                )}{' '}
            </div>
          </div>

          {userInfo.profileInfo && (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <div>Location: {userInfo.profileInfo.location}</div>
              <div>Shoe Size: {userInfo.profileInfo.shoeSize}</div>
              <div>
                Age:{' '}
                {moment().diff(
                  moment(userInfo.profileInfo.dob),
                  'years',
                )}
              </div>
              <div>
                {userInfo.lastOnline &&
                moment(userInfo.lastOnline.toDate()) >
                  moment().add(-10, 'minute') ? (
                  <span style={{ color: '#eb4251' }}>Online</span>
                ) : (
                  userInfo.lastOnline &&
                  'Last seen: ' +
                    moment(userInfo.lastOnline.toDate()).fromNow()
                )}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AuthUserContext.Consumer>
              {(authUser) => {
                return (
                  <button
                    style={{
                      backgroundColor: '#30302e',
                      color: '#f2ebe6',
                      paddingLeft: 15,
                      marginBottom: 10,
                    }}
                    onClick={() =>
                      props.history.push(
                        '/slugs/' +
                          Buffer.from(
                            [authUser.uid, userInfo.docId]
                              .sort()
                              .join('_'),
                          ).toString('base64'),
                      )
                    }
                  >
                    Send Slug{' '}
                    <img
                      style={{ height: 32, width: 32 }}
                      src={require('../../assets/fm-icons-Right_arrow.svg')}
                    />
                  </button>
                );
              }}
            </AuthUserContext.Consumer>

            <AuthUserContext.Consumer>
              {(authUser) => {
                return userInfo && authUser.blockList ? (
                  authUser.blockList.includes(userInfo.docId) ? (
                    <button
                      style={{
                        backgroundColor: '#30302e',
                        color: '#f2ebe6',
                        paddingLeft: 15,
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        props.firebase.db
                          .collection('users')
                          .doc(authUser.uid)
                          .update({
                            blockList: props.firebase.fieldValue.arrayRemove(
                              userInfo.docId,
                            ),
                          })
                          .then(window.location.reload());
                      }}
                    >
                      Unblock User
                      <img
                        style={{ height: 32, width: 32 }}
                        src={require('../../assets/fm-icons-Menu_close.svg')}
                      />
                    </button>
                  ) : (
                    <button
                      style={{
                        backgroundColor: '#30302e',
                        color: '#f2ebe6',
                        paddingLeft: 15,
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        props.firebase.db
                          .collection('users')
                          .doc(authUser.uid)
                          .update({
                            blockList: props.firebase.fieldValue.arrayUnion(
                              userInfo.docId,
                            ),
                          })
                          .then(window.location.reload());
                      }}
                    >
                      Block User
                      <img
                        style={{ height: 32, width: 32 }}
                        src={require('../../assets/fm-icons-Menu_close.svg')}
                      />
                    </button>
                  )
                ) : (
                  <button
                    style={{
                      backgroundColor: '#30302e',
                      color: '#f2ebe6',
                      paddingLeft: 15,
                      marginBottom: 10,
                    }}
                    onClick={() => {
                      props.firebase.db
                        .collection('users')
                        .doc(authUser.uid)
                        .update({
                          blockList: props.firebase.fieldValue.arrayUnion(
                            userInfo.docId,
                          ),
                        })
                        .then(window.location.reload());
                    }}
                  >
                    Block User
                    <img
                      style={{ height: 32, width: 32 }}
                      src={require('../../assets/fm-icons-Menu_close.svg')}
                    />
                  </button>
                );
              }}
            </AuthUserContext.Consumer>

            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser &&
                !!authUser.roles[ROLES.ADMIN] && (
                  <button
                    style={{
                      backgroundColor: '#30302e',
                      color: '#f2ebe6',
                      paddingLeft: 15,
                    }}
                    onClick={() => {
                      props.firebase.db
                        .collection('users')
                        .doc(userInfo.docId)
                        .delete()
                        .then(
                          props.firebase.db
                            .collection('deleted')
                            .doc(userInfo.docId)
                            .set({ deleted: false }),
                        );
                    }}
                  >
                    Delete User!
                    <img
                      style={{
                        height: 32,
                        width: 32,
                      }}
                      src={require('../../assets/fm-icons-Sign-Out.svg')}
                    />
                  </button>
                )
              }
            </AuthUserContext.Consumer>
          </div>
        </SubjectStrip>
      </RecentSlugs>
      <SlugMessages>
        <TitleContainer>
          <h5 style={{ color: '#e6dcd3' }}>Guff</h5>
        </TitleContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ marginLeft: 5 }}>
              {ReactHtmlParser(
                userInfo.profileInfo && userInfo.profileInfo.aboutMe,
              )}
            </div>
            <UserGallery uid={userInfo.docId} />
          </div>
        </SubjectStrip>

        <TitleContainer>
          <h5 style={{ color: '#e6dcd3' }}>Threads</h5>
        </TitleContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {lastTenThreads &&
              lastTenThreads.map((item, i) => {
                return (
                  <Link key={item.docId} to={'/thread/' + item.docId}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: '1.1em',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {item.threadSubject}
                    </span>
                    <span
                      style={{
                        fontSize: '0.8em',
                        color: '#b3aca9',
                      }}
                    >
                      {moment(item.added.toDate()).fromNow()}
                    </span>
                  </Link>
                );
              })}
          </div>
        </SubjectStrip>
      </SlugMessages>
    </MainContainer>
  ) : (
    <div></div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(ProfilePage);
