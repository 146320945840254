import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withAuthorization, withEmailVerification } from '../Session';
import moment from 'moment';
import { compose } from 'recompose';
import styled from 'styled-components';
import AvatarImage from '../AvatarImage';

const ThreadCard = styled.section`
  margin-bottom: 0px;
`;

const IconImage = styled.img`
  height: 30;
  width: 30;
  margin-right: 5px;
`;

const IconContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #30302e;
  overflow: hidden;
  margin-bottom: 10px;
  ${ThreadCard}:hover & {
    background: #eb4251;
  }
`;

const IconStrip = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
`;

const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  // padding: 25px;
  // height: 300px;
  margin-bottom: 5px;
  overflow: hidden;
`;

const MainContainer = styled.div`
  padding-top: 75px;
  width: 60vw;

  @media screen and (max-width: 1024px) {
    padding-top: 75px;
    margin-left: 30;
    margin-right: 30;
    width: calc(100vw - 60px);
  }
`;

const getRandom = () => {
  var min = 0;
  var max = 0.35;
  var random = Math.random() * (max - min) + min;
  return random;
};

const UserListPage = (props) => {
  const [userList, setUserList] = useState([]);
  document.title = 'Facemouth: Users';
  useEffect(() => {
    const unsubscribe = props.firebase
      .users()
      .orderBy('lastOnline', 'desc')
      .where('lastOnline', '>', moment().add(-3, 'month').toDate())
      .onSnapshot((snapshot) => {
        let users = [];

        snapshot.forEach((doc) =>
          users.push({
            username: doc.data().username,
            lastOnline: doc.data().lastOnline,
            profileInfo: doc.data().profileInfo,
            uid: doc.id,
          }),
        );

        setUserList(users);
      });

    return () => {
      unsubscribe();
    };
  }, [props.firebase]);

  return (
    <MainContainer>
      <IconContainer
        style={{
          clipPath: `polygon(${getRandom()}% ${getRandom()}%,${
            100 - getRandom()
          }% ${getRandom()}%,100% 100%,0 100%  )`,
        }}
      >
        <h5 style={{ color: '#f2ebe6' }}>Users</h5>
      </IconContainer>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: 'white',
        }}
      >
        {[
          ...userList
            .filter(
              ({ lastOnline }) =>
                moment(lastOnline.toDate()).toDate() >
                moment().add(-10, 'minutes').toDate(),
            )
            .sort((a, b) => a.username.localeCompare(b.username)),
          ...userList.filter(
            ({ lastOnline }) =>
              moment(lastOnline.toDate()).toDate() <
              moment().add(-10, 'minutes').toDate(),
          ),
        ].map((item, i) => {
          return item.username ? (
            <Link
              style={{ width: '33%', minWidth: 320 - 60 }}
              key={item.uid}
              to={'/u/' + item.username}
            >
              <ThreadCard
                style={{
                  width: 180,
                  padding: 20,
                  display: 'flex',
                  color: '#30302e',
                }}
              >
                <div>
                  <AvatarImage
                    style={{
                      height: 60,
                      width: 60,
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    uid={item.uid}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: 10,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{ fontWeight: 500, fontSize: '0.9em' }}
                  >
                    {item.username}
                  </span>
                  <span style={{ fontSize: '0.8em' }}>
                    {item.lastOnline &&
                    moment(item.lastOnline.toDate()) >
                      moment().add(-10, 'minute') ? (
                      <span style={{ color: '#eb4251' }}>Online</span>
                    ) : (
                      item.lastOnline &&
                      moment(item.lastOnline.toDate()).fromNow()
                    )}
                  </span>
                </div>
              </ThreadCard>
            </Link>
          ) : null;
        })}
      </div>
    </MainContainer>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(UserListPage);
