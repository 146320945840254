import React from 'react';
import SignInPage from '../SignIn';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
// import './style.css'

const Landing = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
      }}
    >
      <AuthUserContext.Consumer>
        {(authUser) => authUser && props.history.push(ROUTES.THREAD)}
      </AuthUserContext.Consumer>

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingTop: 30,
          paddingRight: 20,
          paddingLeft: 20,
          backgroundColor: '#30302e',
          clipPath:
            ' polygon(0 0, 100% 0, 99% 23%, 100% 53%, 99% 76%, 100% 100%, 0 100%)',
        }}
      >
        <img
          style={{ maxWidth: '60vw', width: 350 }}
          src={require('../../assets/fm-icons-Facemouth_logo_large.svg')}
          alt=""
        />
        <h1
          className={'bitSmaller'}
          style={{ color: '#eb4251', textAlign: 'center' }}
        >
          For some reason, you're still here
        </h1>
        <SignInPage />
        <div style={{ flex: 1 }} />
      </div>
      <div id="cloudbg">
        <div style={{ display: 'flex', flex: 1 }} id="cloud-scroll1">
          <div
            style={{ display: 'flex', flex: 1 }}
            id="cloud-scroll2"
          >
            <div
              style={{ display: 'flex', flex: 1 }}
              id="cloud-scroll3"
            >
              <div
                style={{ display: 'flex', flex: 1 }}
                id="cloud-scroll4"
              >
                <div
                  style={{ display: 'flex', flex: 1 }}
                  id="cloud-scroll5"
                >
                  <div
                    style={{ display: 'flex', flex: 1 }}
                    id="cloud-scroll6"
                  >
                    <div
                      style={{ display: 'flex', flex: 1 }}
                      id="cloud-scroll70"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
