import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

const AvatarImage = (props) => {
  const { style } = props;

  const [URL, setURL] = useState();
  const [loaded, setLoaded] = useState(false);
  const placeHolder = '/assets/fm-icons-Users.svg';

  const mainStyle = {
    backgroundColor: '#eb4251',
    position: 'absolute',
    top: 0,
    left: 0,
  };

  const merged = { ...style, ...mainStyle };

  useEffect(() => {
    let mounted = true;
    props.firebase
      .user(props.uid)
      .collection('pictures')
      .orderBy('updated', 'desc')
      .limit(1)
      .get()
      .then((doc) => {
        if (mounted) {
          doc.forEach((doc, i) => {
            props.firebase.storage
              .ref('images')
              .child('thumb_' + doc.data().filename)
              .getDownloadURL()
              .then((url) => {
                if (mounted) {
                  setURL(url);
                }
              });
          });
        }
      });

    return () => (mounted = false);
    
  }, [props.firebase, props.uid]);

  return (
    <div style={{ position: 'relative' }}>
      {URL && (
        <img
          alt="Avatar "
          style={merged}
          src={URL}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      )}
      <img
        style={merged}
        src={placeHolder}
        className={loaded ? 'fadeOutImg' : undefined}
        alt="Avatar Placeholder"
      />
      <div style={style} />
    </div>
  );
};

export default withFirebase(AvatarImage);
