import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import UserList from './UserList';
import styled from 'styled-components';

const MainContainer = styled.div`
  padding-top: 75px;
  width: 60vw;
  height: calc(100% - 75px);
  display: flex;
  @media screen and (max-width: 1024px) {
    padding-top: 75px;
    height: calc(100% - 95px);
    margin-left: 30;
    margin-right: 30;
    width: calc(100vw - 60px);
  }
`;

const RecentSlugs = styled.section`
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`;

const SlugMessages = styled.section`
  flex: 3;
  display: flex;
  flex-direction: column;
  flex: 3;

  @media screen and (max-width: 1024px) {
    margin: 0;
    display: none;
    flex: 0;
  }
`;

const TitleContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #30302e;
  overflow: hidden;
  margin-bottom: 10px;
`;

const IconStrip = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
`;
const SubjectStrip = styled.section`
  a {
    color: #30302e;
  }
  color: #30302e;
  // padding: 25px;

  margin-bottom: 5px;
  overflow: auto;
  @media screen and (max-width: 1024px) {
  }
`;

const SlugList = (props) => {
  return (
    <MainContainer>
      <RecentSlugs>
        <TitleContainer>
          <h5 style={{ color: '#e6dcd3' }}>Recent Slugs</h5>
        </TitleContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,
            flex: 1,
          }}
        >
          <UserList authUser={props.authUser} />
        </SubjectStrip>
      </RecentSlugs>
      <SlugMessages>
        <TitleContainer>
          <h5 style={{ color: '#e6dcd3' }}>&nbsp;</h5>
        </TitleContainer>
        <SubjectStrip
          style={{
            backgroundColor: 'white',
            padding: 20,
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              Click someones name.
            </div>
          </div>
        </SubjectStrip>
      </SlugMessages>
    </MainContainer>
  );
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(SlugList);
