import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <div
    style={{ color: '#f2ebe6' }}
    type="button"
    onClick={firebase.doSignOut}
  >
    Sign Out
  </div>
);

export default withFirebase(SignOutButton);
