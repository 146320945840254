import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';

const GetUserName = (props) => {
  const [userName, setUserName] = useState('');
  useEffect(() => {
    props.firebase
      .user(props.uid)
      .get()
      .then((doc) => doc.exists && setUserName(doc.data().username));
  }, []);
  return userName ? userName : 'Deleted User';
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(GetUserName);
