import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import moment from 'moment';

const GetUserBirthday = (props) => {
  const [userBirthday, setUserBirthday] = useState(false);
  useEffect(() => {
    props.firebase
      .user(props.uid)
      .get()
      .then((doc) => {
        doc.exists &&
          doc.data().profileInfo &&
          doc.data().profileInfo.dob &&
          setUserBirthday(
            moment(doc.data().profileInfo.dob)
              .format('DD-MM')
              .toString() == moment().format('DD-MM').toString(),
          );
      });
  }, []);
  return userBirthday ? (
    <img
      style={{ height: 22, width: 22 }}
      src={require('../../assets/Birthday-cake-01.svg')}
      alt="cake"
    />
  ) : null;
};

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(GetUserBirthday);
